// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validation-error{
    color: rgb(243, 91, 91);
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}
.modal-content{
    padding: 15px !important;
    width: 85% !important;
    border-radius: 15px !important;
}
.modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin-bottom: 10px;
    border: none;
}
.modal-title{
    font-size: 16px;
    font-weight: 600;
}
.modal-close-button{
    padding: 2px 9px;
    border: none;
    font-size: 15px;
    border-radius: 50px;
    background-color: #D22B2B1A;
    cursor: pointer;
    color: rgba(210, 43, 43, 1);
    font-weight: 600;
}
.cp{
    cursor: pointer;
}

.card-header{
    font-size: 18px;
    font-weight: 600;
    color: #333333;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,wBAAwB;IACxB,qBAAqB;IACrB,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,2BAA2B;IAC3B,eAAe;IACf,2BAA2B;IAC3B,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".validation-error{\r\n    color: rgb(243, 91, 91);\r\n    font-size: 13px;\r\n    font-weight: 400;\r\n    margin-bottom: 0px;\r\n}\r\n\r\nbutton:disabled {\r\n    cursor: not-allowed;\r\n    opacity: 0.7;\r\n}\r\n.modal-content{\r\n    padding: 15px !important;\r\n    width: 85% !important;\r\n    border-radius: 15px !important;\r\n}\r\n.modal-header{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 0px;\r\n    margin-bottom: 10px;\r\n    border: none;\r\n}\r\n.modal-title{\r\n    font-size: 16px;\r\n    font-weight: 600;\r\n}\r\n.modal-close-button{\r\n    padding: 2px 9px;\r\n    border: none;\r\n    font-size: 15px;\r\n    border-radius: 50px;\r\n    background-color: #D22B2B1A;\r\n    cursor: pointer;\r\n    color: rgba(210, 43, 43, 1);\r\n    font-weight: 600;\r\n}\r\n.cp{\r\n    cursor: pointer;\r\n}\r\n\r\n.card-header{\r\n    font-size: 18px;\r\n    font-weight: 600;\r\n    color: #333333;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
