import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from '../../Service/api'
import { api_url } from "../../Utils/api-urls";

// for dashboard
export const getCardData = createAsyncThunk("get/getCardData", async ()=>{
    try{
        let response = await api.get(api_url.get_dashboard_data);
        let data = response?.data?.result;
        return data;
    } catch(error){
    }
});

export const getOperationalGraph = createAsyncThunk("get/getOperationalGraph", async (time)=>{
    try{
        let response = await api.get(api_url.optional_overview(time))
        let data = response?.data;
        return data;
    } catch(error){
    }
})

export const getRevenueGraph = createAsyncThunk("get/getRevenueGraph", async (time)=>{
    try{
        let response = await api.get(api_url.revenue(time))
        let data = response?.data?.result;

        return data;
    } catch(error){
    }
})

// for reports
export const getReportData = createAsyncThunk("get/getReportData", async ()=>{
    try{
        let response = await api.get(api_url.report_data);
        let data = response?.data?.result;
        return data;
    } catch(error){
    }
});
export const getRevenueReport = createAsyncThunk("get/getRevenueReport", async (time)=>{
    try{
        let response = await api.get(api_url.revenue_reports(time))
        let data = response?.data?.data;
        return data;
    } catch(error){
    }
})
export const getPlateformReport = createAsyncThunk("get/getPlateformReport", async (time)=>{
    try{
        let response = await api.get(api_url.plateform_reports(time))
        let data = response?.data?.data;

        return data;
    } catch(error){
    }
})

// for finance
export const getFinanceData = createAsyncThunk("get/getFinanceData", async ()=>{
    try{
        let response = await api.get(api_url.finance_data);
        let data = response?.data?.result;
        return data;
    } catch(error){
    }
});
export const getOperationalFinance = createAsyncThunk("get/getOperationalFinance", async (time)=>{
    try{
        let response = await api.get(api_url.optional_overview_finance(time))
        let data = response?.data?.data;
        return data;
    } catch(error){
    }
});
export const getRevenueFinance = createAsyncThunk("get/getRevenueFinance", async (time)=>{
    try{
        let response = await api.get(api_url.revenue_finance(time))
        let data = response?.data?.data;

        return data;
    } catch(error){
    }
});

const reportDataSlice = createSlice({
    name: "reportSlice",
    initialState: {
        dashboardData: {},
        revenue: [],
        operational: [],
        report_data: {},
        revenue_report: [],
        plateform_report: [],
        finance_data: {},
        finance_operational: [],
        finance_revenue: [],
        status: "idle",
        error: null
    },
    extraReducers: (builder) => {
        builder
        .addCase( getCardData.pending ,(state)=>{
            state.status = "pending";
        })
        .addCase( getCardData.fulfilled ,(state, action)=>{
            state.status = "success";
            state.dashboardData = action.payload;
        })
        .addCase( getCardData.rejected ,(state)=>{
            state.status = "reject";
        })

        builder
        .addCase( getOperationalGraph.pending ,(state)=>{
            state.status = "pending";
        })
        .addCase( getOperationalGraph.fulfilled ,(state, action)=>{
            state.status = "success";
            state.operational = action.payload;
        })
        .addCase( getOperationalGraph.rejected ,(state)=>{
            state.status = "reject";
        })

        builder
        .addCase( getRevenueGraph.pending ,(state)=>{
            state.status = "pending";
        })
        .addCase( getRevenueGraph.fulfilled ,(state, action)=>{
            state.status = "success";
            state.revenue = action.payload;
        })
        .addCase( getRevenueGraph.rejected ,(state)=>{
            state.status = "reject";
        })

        builder
        .addCase(getReportData.fulfilled, (state, action)=>{
            state.report_data = action.payload
        })

        builder
        .addCase(getRevenueReport.fulfilled, (state, action)=>{
            state.revenue_report = action.payload
        })

        builder
        .addCase(getPlateformReport.fulfilled, (state, action)=>{
            state.plateform_report = action.payload
        })

        builder
        .addCase(getFinanceData.fulfilled, (state, action)=>{
            state.finance_data = action.payload
        })

        builder
        .addCase(getOperationalFinance.fulfilled, (state, action)=>{
            state.finance_operational = action.payload
        })

        builder
        .addCase(getRevenueFinance.fulfilled, (state, action)=>{
            state.finance_revenue = action.payload
        })

    }
})

export default reportDataSlice.reducer;