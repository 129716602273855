import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { api_url } from "../../Utils/api-urls";
import api from "../../Service/api";

export const getUsersDetails = createAsyncThunk('users/getUsersDetails', async ()=> {
    try{
        let response = await api.get(api_url.users)
        let data = await response?.data;
        return data;
    } catch(error){
    }
})

const userSlice = createSlice({
    name: 'users',
    initialState: {
        items: {},
        status: 'idle',
        error: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getUsersDetails?.pending, (state)=>{
            state.status = "pending";
        })
        .addCase(getUsersDetails?.fulfilled, (state, action)=>{
            state.status = "success";
            state.items = action.payload;  
        })
        .addCase(getUsersDetails?.rejected, (state, action)=>{
            state.status = "rejected";
            state.error = action.payload.message;
        })
    }
})

export const {setData, getData} = userSlice;
export default userSlice.reducer;