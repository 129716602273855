import React, {lazy} from 'react'
import {Navigate, Routes, Route} from 'react-router-dom'
import Layout from './Layout';
import ProtectedRoute from './ProtectedRoute';
import NotFound from '../../Pages/Error/NotFount';
import PublicRoute from './PublicRoute';

import Login from '../../Pages/Auth/Login';
import Forgot from '../../Pages/Auth/ForgotPass/index'

//user
const UserManagement = lazy(()=> import('../../Pages/UserManagement'));
const Report = lazy(()=> import('../../Pages/Report'));
const Event = lazy(()=> import('../../Pages/Event'));
const Setting = lazy(()=> import('../../Pages/setting'));
const Dashboard = lazy(()=> import('../../Pages/Home'));
const Odds = lazy(()=> import('../../Pages/Odds'));
const Finance = lazy(()=> import('../../Pages/Finance'));
 
//event
const CreateEvent = lazy(()=> import('../../Pages/Event/createEvent'));
const ViewEvent = lazy(()=> import('../../Pages/Event/ViewEvent'));
const ViewOptionUser = lazy(()=> import('../../Pages/Event/ViewOptionsUser'));
const Preview = lazy(()=> import('../../Pages/Event/Preview'));
  
//user
const SingleUser = lazy(()=> import('../../Pages/UserManagement/SingleUser'));
const Template = lazy(()=> import('../../Pages/Event/template'));
const SingleEventOds = lazy(()=> import('../../Pages/Odds/singleEventOds'));
const ContentMgmt = lazy(()=> import('../../Pages/CMS'));
const Coupen = lazy(()=> import('../../Pages/Coupen'));
const CalcManagement = lazy(()=> import('../../Pages/CalcManagement'));
const Pools = lazy(()=> import('../../Pages/Pools'));
const ComboDetail = lazy(()=> import('../../Pages/UserManagement/comboPanalo'));
const Affiliate = lazy(()=> import('../../Pages/Affiliate/Index'));
const Payborit = lazy(()=> import('../../Pages/Payments/payChannel'));
    
export default function Routers(){
    const protectedRoute = [
        {
            path: 'dashboard',
            element: <Dashboard/>
        },
        {
            path: 'settings',
            element: <Setting/>
        },
        {
            path: 'event-management',
            element: <Event/>
        },
        {
            path: 'event-details/:id',
            element: <ViewEvent/>
        },
        {
            path: 'template-details/:id',
            element: <ViewEvent/>
        },
        {
            path: 'option-details/:optionid',
            element: <ViewOptionUser/>
        },
        {
            path: 'report-management',
            element: <Report/>
        },
        {
            path: 'odds-management',
            element: <Odds/>
        },
        {
            path: "odds-details/:id",
            element: <SingleEventOds/>
        },
        {
            path: 'finance-management',
            element: <Finance/>
        },
        {
            path: 'create-event',
            element: <CreateEvent/>
        },
        {       
            path: 'edit-event/:id',
            element: <CreateEvent/>
        },
        {       
            path: 'edit-template/:id',
            element: <CreateEvent/>
        },
        {
            path: 'user-management',
            element: <UserManagement/>
        },
        {
            path: 'user-details',
            element: <SingleUser/>
        },
        {
            path: "combo-panalo-details/:id",
            element: <ComboDetail/>
        },
        {
            path: 'event-preview',
            element: <Preview/>
        },
        {
            path: "event-template",
            element: <Template/>
        },
        {
            path: "content-management",
            element: <ContentMgmt/>
        },
        {
            path: "coupon-management",
            element: <Coupen/>
        },
        {
            path: "payout-management",
            element: <CalcManagement/>
        },
        {
            path: "pool-management",
            element: <Pools/>
        },
        {
            path: "affiliate-management",
            element: <Affiliate/>
        },
        {
            path: "payment-management/payborit",
            element: <Payborit/>
        }
    ];

    const token = localStorage.getItem('jwt');
    
    return(
        <Routes>
            <Route path='/' element={<Navigate to ={token ? "/dashboard" : "/login"}/>}/>
            <Route path='' element={<PublicRoute/>}>
                <Route path="login" element={<Login/>}/>
                <Route path="forgot-password" element={<Forgot/>}/>
            </Route>
            <Route path="" element={<Layout/>}>
                <Route path="" element={<ProtectedRoute/>}>
                    {
                        protectedRoute?.map((data, index)=>{
                            return <Route key={index} path={data?.path} element={data?.element}/>
                        })
                    }
                </Route>
            </Route>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
}