import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api_url } from "../../Utils/api-urls";

export const getEventPool = createAsyncThunk('pool/getEventPool', async () => {
    try{
        let response = await axios.get(api_url.getPool, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })

        const data = await response?.data?.data;

        return data
    } catch(error) {
    }
});

export const getPoolHistory = createAsyncThunk('pool/pooHistory', async () => {
    try{
        let response = await axios.get(api_url.pool_history,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: "application/json"
            }
        })

        const data = await response?.data?.data;

        return data
    } catch(error){
    }
})


const poolSlice = createSlice({
    name: "poolSlice",
    initialState: {
        eventPoolData: [],
        eventPoolHist: [],
        status: 'idle',
        error: null
    },
    reducer: {},
    extraReducers: (building)=>{
        building
        .addCase(getEventPool.pending, (state)=>{
            state.status = 'pending';
        })
        .addCase(getEventPool.fulfilled, (state, action)=>{
            state.status = 'complete';
            state.eventPoolData = action.payload;
        })
        .addCase(getEventPool.rejected, (state, action)=>{
            state.status = 'reject';
            state.error = action.error.message;
        })

        building
        .addCase(getPoolHistory.pending, (state)=>{
            state.status = 'pending';
        })
        .addCase(getPoolHistory.fulfilled, (state, action)=>{
            state.status = 'complete';
            state.eventPoolHist = action.payload;
        })
        .addCase(getPoolHistory.rejected, (state, action)=>{
            state.status = 'reject';
            state.error = action.error.message;
        })
    }
})

export const {setData, getData} = poolSlice;

// export const {addHistory} = poolSlice.actions;
export default poolSlice.reducer;