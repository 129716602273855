// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-header{
    padding: 10px;
    /* height: 10vh; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sidebar-main{
    width: 250px !important;
    min-width: 250px !important;
}
.sidebar-header-collapse{
    /* padding: 10px; */
    /* height: 10vh; */
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}
.menu-collapsed{
    padding: 0px 10px;
}
.menu-not-collapsed{
    padding: 0px 10px;
}

.this-is-menuitem > a {
    justify-content: center;
    padding-left: 28px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Sidebar/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,uBAAuB;IACvB,2BAA2B;AAC/B;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".sidebar-header{\r\n    padding: 10px;\r\n    /* height: 10vh; */\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n.sidebar-main{\r\n    width: 250px !important;\r\n    min-width: 250px !important;\r\n}\r\n.sidebar-header-collapse{\r\n    /* padding: 10px; */\r\n    /* height: 10vh; */\r\n    margin-top: 10px;\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n    align-items: center;\r\n}\r\n.menu-collapsed{\r\n    padding: 0px 10px;\r\n}\r\n.menu-not-collapsed{\r\n    padding: 0px 10px;\r\n}\r\n\r\n.this-is-menuitem > a {\r\n    justify-content: center;\r\n    padding-left: 28px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
