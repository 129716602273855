import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Routers from './Component/RoutesAndLayout';
import ProgressBar from './Hooks/use-nprogress';
import { useNavigate } from 'react-router-dom';

const useAutoLogout=(expireIn)=>{  
  const navigate = useNavigate();
  useEffect(() => {
    // Calculate the expiration time in milliseconds
    const expirationTime = expireIn * 1000; 

    // Schedule the logout
    const timer = setTimeout(() => {
        localStorage.clear();
        navigate('/login')
    }, expirationTime);

    // Cleanup timer if component unmounts
    return () => clearTimeout(timer);
  }, [expireIn]);
}
function App() {
  useAutoLogout(localStorage.getItem('expire_in'))
  return (<>
    <ProgressBar/>
      <Routers/>
    <ToastContainer/>
  </>);
}

export default App;