import styled from 'styled-components';

export const Heading = styled.p`
font-size: 30px;
font-weight: 700;
margin-bottom: 0px;
color: #333333;
`
export const SubHeading = styled.p`
margin-bottom: 0px; 
color: #A9A9A9;
font-size: 14px;
font-weight: 300;
`
export const Label = styled.label`
font-weight: 500;
color: #666666;
font-size: 14px;
`

export const InputContainer = styled.div`
width: 100%;    
border: 1px solid #A9A9A98C;
border-radius: 8px;
display: flex;
align-items: center;
padding: 7px 4px;
`
export const Input = styled.input`
border: none;
outline: none;
// padding: 10px;
font-size: 12px;
font-weight: 400px;
width: 100%;
::placeholder{
    color: #A9A9A9;
}
`
export const Button = styled.button`
background: #D22B2B;
width: 100%;
border: none;
border-radius: 10px;
color: white;
font-size: 16px;
font-weight: 600;
display: flex;
justify-content: center;
align-items: center;    
padding: 5px;
&:disabled{
    opacity: 0.9;
}
`