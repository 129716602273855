import React from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Image from '../../images/headerImage.png'
import Notification from '../../images/notification.png'
const Div = styled.div`
hegiht: 10vh;
border-bottom: 1px solid #D9DBE9;
background: white;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px;

.heading{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 13px;
}

.sub-heading{
    font-size: 13px;
    font-weight: 500;
    color: #A9A9A9;
}
`

export default function Navbar(){
    const location = useLocation();
        
    const getByTitle=()=>{

        if(location?.pathname?.includes("dashboard")) return ["Dashboard","dashboard"];
        else if(location?.pathname?.includes("settings")) return ["Settings","settings"];

        else if(location?.pathname?.includes("event-management")) return ["Events","events"];
        else if(location?.pathname?.includes("event-details")) return ["Events","event/event-details"];
        else if(location?.pathname?.includes("option-details")) return ["Events","event/event-details/option-details"];
        else if(location?.pathname?.includes("create-event")) return ["Events","event/create-event"];
        else if(location?.pathname?.includes("edit-event")) return ["Events","event/edit-event"];
        else if(location?.pathname?.includes("event-preview")) return ["Events","event/create-event/preview-event"];
        else if(location?.pathname?.includes("event-template")) return ["Events","event/create-event/event-template"];
        else if(location?.pathname?.includes("edit-template")) return ["Events","event/create-event/event-template/edit-template"];
        else if(location?.pathname?.includes("template-details")) return ["Events","event/create-event/event-template/template-details"];
        
        else if(location?.pathname?.includes("user-management")) return ["Users","user"];
        else if(location?.pathname?.includes("user-details")) return ["Users","user/user-details"];
        else if(location?.pathname?.includes("combo-panalo-details")) return ["Users","user/user-details/combo-panalo-details"];
        else if(location?.pathname?.includes("single-event-details")) return ["Users","user/user-details/event-details"];

        else if(location?.pathname?.includes("affiliate-management")) return ["Affiliate","affiliates"];

        else if(location?.pathname?.includes("report-management")) return ["Reports","report"];

        else if(location?.pathname?.includes("odds-management")) return ["Odds","odds"];
        else if(location?.pathname?.includes("odds-details")) return ["Odds","odds/odds-detail"];
        
        else if(location?.pathname?.includes("finance-management")) return ["Finance","finance"];

        else if(location?.pathname?.includes("content-management")) return ["CMS","cms"];

        else if(location?.pathname?.includes("coupon-management")) return ["Coupons","coupons"];

        else if(location?.pathname?.includes("payout-management")) return ["Fees & Payout","payout calculation"];
        else if(location?.pathname?.includes("pool-management")) return ["Liquidity & Pools","pools"];

        else if(location?.pathname?.includes("payment-management/payborit")) return ["Payment Channels","payborit 2.0"];
        

    }
    return(
        <Div>
            <div>
                <p className='heading mb-0'>{getByTitle()[0]}</p>
                <small className='sub-heading'>{getByTitle()[1]}</small>
            </div>

            <div className='d-flex align-items-center gap-2'>
                <img src={Notification}/>
                <img src={Image}/>
            </div>
        </Div>
    );
}