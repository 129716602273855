import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "authSlice", 
    initialState: {
        authToken: "",
        status: "idle"
    },
    reducer: {
        setAuthToken: (state, action)=>{
            const value = action.payload;
            state.authToken = value;
        },

        resetAuthToken: (state)=>{
            state.authToken= "";
        }
    }
})

export default authSlice.reducer;
export const {setAuthToken, resetAuthToken} = authSlice.actions;