// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-otp > div > input{
    width: 20% !important;
    border: 1px solid rgba(169, 169, 169, 0.55);
    border-radius: 8px;
    color: black;
    padding: 6px 0px;
    outline: none;
}

.forgot-otp > div{
    justify-content: space-around; 
}`, "",{"version":3,"sources":["webpack://./src/Pages/Auth/ForgotPass/index.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,2CAA2C;IAC3C,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".forgot-otp > div > input{\r\n    width: 20% !important;\r\n    border: 1px solid rgba(169, 169, 169, 0.55);\r\n    border-radius: 8px;\r\n    color: black;\r\n    padding: 6px 0px;\r\n    outline: none;\r\n}\r\n\r\n.forgot-otp > div{\r\n    justify-content: space-around; \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
